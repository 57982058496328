import { NavigationBar } from "@components/organisms/navigation"
import React, { ReactElement, ReactNode } from "react"

export const Layout = ({ children }: { children: ReactNode }): ReactElement => (
  <main>
    <title>internet turntable - vinyl on the internet</title>
    <NavigationBar />
    {children}
  </main>
)
