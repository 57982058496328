import { BuBlock } from "@components/atoms/bu-block"
import { BuHeading } from "@components/atoms/bu-heading"
import { BuLevel } from "@components/layout/bu-level"
import { BuNavbar } from "@components/molecules/bu-navbar"
import { Player } from "@components/organisms/navigation/player"
import React from "react"
import { Logo } from "./logo"

export const NavigationBar = () => (
  <BuNavbar color="white" fixed="top">
    <BuNavbar.Brand>
      <BuLevel breakpoint="mobile">
        <BuLevel.Item px={4}>
          <Logo size={48} />
        </BuLevel.Item>
        <BuBlock>
          <BuHeading size={5} pt={1}>
            internet turntable
          </BuHeading>
          <BuHeading subtitle size={5} renderAs="h2">
            vinyl on the internet
          </BuHeading>
        </BuBlock>
        <Player />
      </BuLevel>
    </BuNavbar.Brand>
  </BuNavbar>
)

export { Logo } from "./logo"
