import { BuBlock } from "@components/atoms/bu-block"
import { BuHeading } from "@components/atoms/bu-heading"
import { useIcecastPlayer } from "@hooks/use-icecast-player"
import React from "react"
import { useIsomorphicLayoutEffect } from "usehooks-ts"

export const Player = () => {
  const { player, playerState, title, playAudio } = useIcecastPlayer(
    "https://listen.internettt.xyz:8443/listen-mbr",
  )

  useIsomorphicLayoutEffect(() => {
    console.log("about to play", window)
    playAudio().then(() => {
      console.log("playing", playerState)
    })
  }, [player])

  return (
    <BuBlock>
      <BuHeading size={5} pt={1}>
        {title}
      </BuHeading>
      <BuHeading subtitle size={5} renderAs="h2">
        {playerState}
      </BuHeading>
    </BuBlock>
  )
}
