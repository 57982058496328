import { BuImage } from "@components/atoms/bu-image"
import React, { CSSProperties, memo } from "react"

type LogoProps = {
  size?:
    | 16
    | 24
    | 32
    | 48
    | 64
    | 96
    | 128
    | "square"
    | "1by1"
    | "4by3"
    | "3by2"
    | "16by9"
    | "2by1"
    | "5by4"
    | "5by3"
    | "3by1"
    | "4by5"
    | "3by4"
    | "2by3"
    | "3by5"
    | "9by16"
    | "1by2"
    | "1by3"
    | string
    | number
  style?: CSSProperties
}

export const Logo = memo(({ size, style }: LogoProps) => (
  <BuImage
    alt="record and tonearm"
    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512;' xml:space='preserve' data-arp-injected='true'%3E%3Cg%3E%3Cg%3E%3Cg%3E%3Ccircle cx='256' cy='256' r='21.333'/%3E%3Cpath d='M489.75,151.896c-2.063-4.583-6.917-7.115-11.979-6.094c-4.917,1.052-8.438,5.406-8.438,10.438v142.427 c0,2.802-1.146,5.552-3.125,7.542l-38.25,38.25c-4.167,4.167-4.167,10.917,0,15.083l6.25,6.25 c2.021,2.021,3.125,4.698,3.125,7.542s-1.104,5.521-3.125,7.542l-64,64c-4.042,4.021-11.042,4.021-15.083,0l-6.25-6.25 c-4.167-4.167-10.917-4.167-15.083,0l-6.25,6.25c-4.042,4.021-11.042,4.021-15.083,0c-2.021-2.021-3.125-4.698-3.125-7.542 s1.104-5.521,3.125-7.542l6.25-6.25c4.167-4.167,4.167-10.917,0-15.083l-6.25-6.25c-2.021-2.021-3.125-4.698-3.125-7.542 s1.104-5.521,3.125-7.542l64-64c4.042-4.021,11.042-4.021,15.083,0l6.25,6.25c4.167,4.167,10.917,4.167,15.083,0l32-32 c2-2,3.125-4.708,3.125-7.542V10.667C448,4.771,443.229,0,437.333,0c-5.896,0-10.667,4.771-10.667,10.667V65.62 C379.874,23.669,319.012,0,256,0C114.833,0,0,114.844,0,256s114.833,256,256,256s256-114.844,256-256 C512,220.031,504.521,185.01,489.75,151.896z M127.563,138.667c-2.729,0-5.438-1.042-7.521-3.115 c-4.188-4.156-4.188-10.906-0.042-15.083C156.292,84.052,204.583,64,256,64c5.896,0,10.667,4.771,10.667,10.667 c0,5.896-4.771,10.667-10.667,10.667c-45.688,0-88.625,17.823-120.875,50.198C133.042,137.625,130.292,138.667,127.563,138.667z M149.333,256c0-58.813,47.854-106.667,106.667-106.667S362.667,197.188,362.667,256S314.813,362.667,256,362.667 S149.333,314.813,149.333,256z'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E"
    size={size ?? "square"}
    fullwidth={!size}
    style={style}
  />
))
