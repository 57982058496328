import IcecastMetadataPlayer, {
  IcecastMetadataPlayerIcyOptionsWithCallbacks,
} from "icecast-metadata-player"
import { useCallback, useMemo, useState } from "react"
import { useSsr } from "usehooks-ts"

export enum IcecastPlayerState {
  Created = "created",
  Loading = "loading",
  Starting = "starting",
  Buffering = "buffering",
  Playing = "playing",
  Reconnecting = "reconnecting",
  ReconnectFailed = "reconnectFailed",
  Ending = "ending",
  Stopped = "stopped",
}

export const useIcecastPlayer = (url: string) => {
  const [title, setTitle] = useState<string | undefined>(undefined)
  const [playerState, setPlayerState] = useState<
    IcecastPlayerState | undefined
  >(undefined)

  const { isBrowser } = useSsr()

  const player = useMemo(() => {
    const icecastPlayer = isBrowser
      ? new IcecastMetadataPlayer(url, {
          onMetadata: (metadata) => {
            setTitle(metadata.StreamTitle)
          },
          onLoad: () => {
            setPlayerState(IcecastPlayerState.Loading)
          },
          onStreamStart: () => {
            setPlayerState(IcecastPlayerState.Starting)
          },
          onBuffer: () => {
            setPlayerState(IcecastPlayerState.Buffering)
          },
          onPlay: () => {
            setPlayerState(IcecastPlayerState.Playing)
          },
          onStreamEnd: () => {
            setPlayerState(IcecastPlayerState.Ending)
          },
          onStopped: () => {
            setPlayerState(IcecastPlayerState.Stopped)
          },
          onRetry: () => {
            setPlayerState(IcecastPlayerState.Reconnecting)
          },
          onRetryTimeout: () => {
            setPlayerState(IcecastPlayerState.ReconnectFailed)
          },
          metadataTypes: ["icy"],
        } as IcecastMetadataPlayerIcyOptionsWithCallbacks)
      : undefined
    if (icecastPlayer) setPlayerState(IcecastPlayerState.Created)
    return icecastPlayer
  }, [url, isBrowser])

  const playAudio = useCallback(
    () => player?.play() || Promise.resolve(),
    [player],
  )
  const stopAudio = useCallback(
    () => player?.stop() || Promise.resolve(),
    [player],
  )

  return { player, playerState, title, playAudio, stopAudio }
}
